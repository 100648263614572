<template>
  <div class="sign-in">
    <ui-container class="sign-in__locale-wrp">
      <locale-switcher class="sign-in__locale-switcher" />
    </ui-container>

    <ui-container class="sign-in__container">
      <sign-in-form />
    </ui-container>
  </div>
</template>

<script>
import { UiContainer } from '@shelf.network/ui-kit'
import LocaleSwitcher from 'Common/LocaleSwitcher'
import SignInForm from './components/SignInForm'

export default {
  name: 'sign-in',
  components: {
    UiContainer,
    SignInForm,
    LocaleSwitcher,
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-in {
  display: flex;
  flex: 1;
  margin: 0;

  &__locale-wrp {
    margin-top: 1.5em;
    text-align: right;
  }

  &__locale-switcher {
    @include respond-below(sm) {
      /deep/ .ui-dropdown__content {
        left: unset;
        right: -0.6em;

        &::before {
          left: unset;
          right: 1em;
        }
      }
    }
  }

  &__container {
    max-width: 34em;
    align-self: center;
    display: flex;
    flex: 1;
    align-items: center;
  }
}
</style>

<i18n>
{
  "en": {
    "META_TITLE": "Sign In"
  },
  "ka": {
    "META_TITLE": "შესვლა"
  },
  "ru": {
    "META_TITLE": "Войти"
  },
  "uk": {
    "META_TITLE": "Увійти"
  }
}
</i18n>
