<template>
  <form
    class="sign-in-form"
    @submit.prevent="submit"
    novalidate
  >
    <h3 class="sign-in-form__title">
      {{ $t('TITLE') }}
    </h3>

    <p
      v-if="isCredentialsError"
      class="sign-in-form__credentials-error"
    >
      {{ $t('CREDENTIALS_ERROR') }}
    </p>

    <fieldset class="sign-in-form__fields">
      <ui-text
        v-model.trim="form.platform"
        class="sign-in-form__field"
        name="sign-in-platform"
        fill="none"
        :placeholder="$t('PLATFORM_PH')"
        :is-error="form.errors.platform !== ''"
        :is-disabled="isFormSubmitting"
        @input="resetFormErrors"
      >
        <span
          slot="error"
          class="sign-in-form__error"
        >
          {{ $t(form.errors.platform) }}
        </span>
      </ui-text>

      <ui-text
        v-model.trim="form.email"
        class="sign-in-form__field"
        name="sign-in-email"
        fill="none"
        :placeholder="$t('EMAIL_PH')"
        :is-error="form.errors.email !== ''"
        :is-disabled="isFormSubmitting"
        @input="resetFormErrors"
      >
        <span
          slot="error"
          class="sign-in-form__error"
        >
          {{ $t(form.errors.email) }}
        </span>
      </ui-text>

      <ui-text
        v-model="form.password"
        type="password"
        class="sign-in-form__field"
        name="sign-in-password"
        fill="none"
        :placeholder="$t('PASSWORD_PH')"
        :is-error="form.errors.password !== ''"
        :is-disabled="isFormSubmitting"
        @input="resetFormErrors"
      >
        <span
          slot="error"
          class="sign-in-form__error"
        >
          {{ $t(form.errors.password) }}
        </span>
      </ui-text>
    </fieldset>

    <ui-button
      class="sign-in-form__submit-btn"
      type="submit"
      :is-disabled="isFormSubmitting"
    >
      <template v-if="isFormSubmitting">
        {{ $t('PROCESSING') }}
      </template>

      <template v-else>
        {{ $t('SIGN_IN_BTN') }}
      </template>
    </ui-button>

    <p class="sign-in-form__version">
      {{ $t('VERSION_TXT', { version: APP_VERSION }) }}
    </p>
  </form>
</template>

<script>
import { UiText, UiButton } from '@shelf.network/ui-kit'

import { mapGetters, mapActions } from 'vuex'
import { userActions } from 'Store/entities/User/types'
import { platformsGetters } from 'Store/entities/Platforms/types'

import { APP_VERSION } from 'Constants/appVersion'
import get from 'lodash/get'

export default {
  name: 'sign-in-form',
  components: {
    UiText,
    UiButton
  },

  data () {
    return {
      form: {
        email: '',
        password: '',
        platform: '',
        errors: {
          email: '',
          password: '',
          platform: ''
        }
      },
      isFormSubmitting: false,
      isCredentialsError: false,
      APP_VERSION
    }
  },

  computed: {
    ...mapGetters('entities/platforms', {
      platforms: platformsGetters.PLATFORMS,
    }),

    isFormValid () {
      return Object.values(this.form.errors).every(item => item === '')
    },

    allowedPlatforms () {
      return Object.values(this.platforms)
        .filter(p => get(p, 'frontFeatureFlags.allowAdmin', false))
    },

    platformsMap () {
      return this.allowedPlatforms.reduce((acc, platform) => ({
        ...acc,
        [platform.code.toUpperCase()]: platform,
        [platform.id]: platform
      }), {})
    }
  },

  created () {
    const platformCode = this.$route.query.platform || ''
    const platformId = localStorage.getItem('platformId') || ''
    const platform = (platformCode || platformId).toUpperCase()

    this.form.platform = get(this.platformsMap, `${platform}.code`, '')
  },

  methods: {
    ...mapActions('entities/user', {
      loginUser: userActions.LOGIN_USER
    }),

    async signIn ({ email, password, platformId }) {
      await this.loginUser(
        { platformId, email, password },
      )
      localStorage.setItem('platformId', platformId)
    },

    async submit () {
      this.validatePlatform()
      this.validateField('email')
      this.validateField('password')

      if (this.isFormValid) {
        this.isCredentialsError = false
        this.isFormSubmitting = true

        try {
          const platformCode = this.form.platform.toUpperCase()

          await this.signIn({
            platformId: get(this.platformsMap, `${platformCode}.id`, ''),
            email: this.form.email.toLowerCase(),
            password: this.form.password
          })
          this.$router.push('/')
        } catch (e) {
          this.isFormSubmitting = false
          this.isCredentialsError = true
        }
      }
    },

    validatePlatform () {
      const platformCode = this.form.platform.toUpperCase()
      const isPlatformValid = Boolean(this.platformsMap[platformCode])

      this.form.errors.platform = isPlatformValid ? '' : 'ERROR_PLATFORM'
    },

    validateField (field) {
      if (this.form[field] === '') {
        this.form.errors[field] = 'ERROR_REQUIRED'
      } else {
        this.form.errors[field] = ''
      }
    },

    resetFormErrors () {
      this.isCredentialsError = false
      this.form.errors = {
        email: '',
        password: '',
        platform: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-in-form {
  background-color: $color-white;
  padding: 4em;
  margin: 0 auto;
  box-shadow: $static-box-shadow;
  border-radius: 10px;
  width: 100%;

  @include respond-below(xs) {
    box-shadow: none;
    padding: 1.6em;
  }

  &__title {
    @extend %h3;

    padding: 0;
    margin: 0;
  }

  &__credentials-error {
    margin-top: 1.2em;
    color: $color-flag-is-error;
  }

  &__fields {
    border: none;
  }

  &__field {
    margin-top: 1.6em;

    &:first-child {
      margin-top: 2em;
    }
  }

  &__error {
    font-size: 0.8em;
  }

  &__submit-btn {
    margin-top: 3.2em;
    width: 100%;
  }

  &__version {
    display: flex;
    justify-content: center;
    margin-top: 1.6em;
    color: $color-dark-grey;
  }

  /deep/ .ui-text {
    &__input {
      border: none;
      border-bottom: 1px solid $color-light-grey !important;
      border-radius: 0;
      background-color: $color-white !important;
      padding: 0;
      box-shadow: none !important;

      &:focus,
      &:hover {
        border-color: $color-dark !important;
      }
    }
  }

  /deep/ .ui-select {
    &__button {
      &-lbl_placeholder {
        color: #c2c2c2;
      }

      /deep/ .ui-button__button {
        transition: border-color 0.2s ease;

        &:hover {
          border-color: $color-dark;
        }
      }
    }

    &__dropdown {
      background-color: $color-white;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "TITLE": "Sign In",
    "PLATFORM_PH": "Platform",
    "EMAIL_PH": "Email",
    "PASSWORD_PH": "Password",
    "PROCESSING": "Processing…",
    "ERROR_REQUIRED": "Please fill this field",
    "ERROR_PLATFORM": "Unknown platform",
    "SIGN_IN_BTN": "Sign In",
    "CREDENTIALS_ERROR": "Incorrect email or password",
    "VERSION_TXT": "v.{version}"
  },
  "ka": {
    "TITLE": "შესვლა",
    "PLATFORM_PH": "პლატფორმა",
    "EMAIL_PH": "ელ. ფოსტა",
    "PASSWORD_PH": "პაროლი",
    "PROCESSING": "მუშავდება…",
    "ERROR_REQUIRED": "გთხოვთ შეავსოთ ველი",
    "ERROR_PLATFORM": "უცნობი პლატფორმა",
    "SIGN_IN_BTN": "შესვლა",
    "CREDENTIALS_ERROR": "არასწორი ელ. ფოსტა ან პაროლი",
    "VERSION_TXT": "v.{version}"
  },
  "ru": {
    "TITLE": "Войти",
    "PLATFORM_PH": "Платформа",
    "EMAIL_PH": "Электронный адрес",
    "PASSWORD_PH": "Пароль",
    "PROCESSING": "Обработка...",
    "ERROR_REQUIRED": "Пожалуйста, заполните это поле",
    "ERROR_PLATFORM": "Неправильная платформа",
    "SIGN_IN_BTN": "Войти",
    "CREDENTIALS_ERROR": "Неправильный адрес электронной почты или пароль",
    "VERSION_TXT": "v.{version}"
  },
  "uk": {
    "TITLE": "Увійти",
    "PLATFORM_PH": "Платформа",
    "EMAIL_PH": "Електронна адреса",
    "PASSWORD_PH": "Пароль",
    "PROCESSING": "Обробка...",
    "ERROR_REQUIRED": "Будь ласка, заповніть це поле",
    "ERROR_PLATFORM": "Неправильна платформа",
    "SIGN_IN_BTN": "Увійти",
    "CREDENTIALS_ERROR": "Неправильна адреса електронної пошти або пароль",
    "VERSION_TXT": "v.{version}"
  }
}
</i18n>
